import React from 'react'
import { Collapse } from 'antd'
import {Link} from 'gatsby'

const Panel = Collapse.Panel;
const ApiCollapse = () => (
    <Collapse defaultActiveKey={['1']} className="sidebar" >
        <Panel header="Introduction" key="1">
            <ul className="links">
                <li><Link to="/api/#introduction-to-grapqhl">Introduction to GraphQL</Link></li>
                <li><Link to="/api/#making-requests">Making requesuts</Link></li>
                <li><Link to="/api/#rest-api-support">REST API support</Link></li>
            </ul>
        </Panel>
        <Panel header="Query" key="2">
            <ul className="links">
                <li><Link to="/query/#fields">Fields</Link></li>
            </ul>
        </Panel>
        <Panel header="Mutations" key="3">
            <ul className="links">
                <li><Link to="/mutations/#fields">Fields</Link></li>
            </ul>
        </Panel>
        <Panel header="Subscriptions" key="4">
            <ul className="links">
                <li><Link to="/subscriptions/#fields">Fields</Link></li>
            </ul>
        </Panel>
        <Panel header="Objects" key="5">
            <ul className="links">
                <li><Link to="/objects/#fields">Fields</Link></li>
            </ul>
        </Panel>
    </Collapse>
)

export default ApiCollapse