import React from 'react'
import Layout from './layout'
import Field from './field'
import ApiCollapse from './apiCollapse'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'

const APIRootType =  ({data: {allApiJson: {edges}}}) => {
    let node = edges[0].node
    return (
      <Layout>
          <main>
            <div className="main-wrapper">
                <h1 className="title">GraphQL API - {node.type}</h1>
                <div className="api">
                    <ApiCollapse />
                    <div className="contents">
                        <p>{node.description}</p>
                        <h2 id="fields">Fields</h2>
                        {node.data.map(field => <Field field={field} key={field.name}/>)}
                    </div>
                </div>
                
            </div>
        </main>
      </Layout>
    )
}

export const query = graphql`
query allApiJsonQuery($page: String!){
	allApiJson(filter: {page: { eq: $page }}){
  	edges {
      node {
        type,
        description
        data {
          name
          description
          return
          returnUrl
          data {
            key
            args
            type
            description
          }
        }
      }
    }
  }
}
`

APIRootType.propTypes = {
  edges: PropTypes.object
}

export default APIRootType