import React from 'react'
import {Table, Tag} from 'antd'
import {Link} from 'gatsby'
import PropTypes from 'prop-types'

const columns = [{
    title: 'Args',
    dataIndex: 'args',
    key: 'args'
}, {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    render: type => (
        <Tag color='#4630eb'>{type}</Tag>
    ),
}, {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
}];

const Field = ({field}) => (
    <div className="field-entry">
        <p className="field-meta">
            <b className="field-name">{field.name} </b>
            {field.return &&
                <Link to={`/objects/#${field.returnUrl}`}><span className="field-return-type">({field.return})</span></Link>
            }
        </p>
        <p className="field-description">{field.description}</p>
        <div className="field-table">
            <Table columns={columns} dataSource={field.data} />
        </div>
    </div>  
)

Field.propTypes = {
    field: PropTypes.object
}


export default Field